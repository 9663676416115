(function () {
  angular.module('mainApp').controller('HomeController', [
    function (
      $scope,
      $http,
      $filter,
      $rootScope,
      $log,
      _,
      appConfig,
      saRouter,
      serviceApiBlog,
      serviceLandings,
      bannersFactory,
      webStorage,
      $timeout,
      $location,
      $document
    ) {
      var badges;
      $scope.today = $filter('date')(new Date(), 'yyyy-MM-dd hh:mm:ss');
      $scope.country = appConfig.region;
      $scope.extraContact =
        $rootScope.translations.help.contact.telesales.enabled_home;

      $log.info($scope.country);

      $http({
        method: 'get',
        url: appConfig.mycookTouchDomain + '/socialfeed/',
        params: {
          limit: 15,
        },
      }).then(function (res) {
        $scope.socialfeed = $filter('filter')(
          res.data,
          { type: '!user/new' },
          true
        ); //res.data;
        badges = $rootScope.translations.badges;
      });

      $scope.getSuggestedRecipes = function () {
        $http({
          method: 'get',
          url: appConfig.mycookTouchDomain + '/recipes/recomendations',
          params: {
            size: 8,
          },
        }).then(function (res) {
          $scope.suggestedRecipes = res.data.recipes;
        });
      };

      $scope.getAuthorUrlLink = function (userNiceName, userDisplayName) {
        var route = saRouter.getRoute('authorRecipeList');
        var url = saRouter.replaceUrlParams(route.url, { user: userNiceName });
        return (
          '<a href="' +
          url +
          '" title="' +
          userDisplayName +
          '" rel="nofollow">' +
          userDisplayName +
          '</a>'
        );
      };

      // find Badge by code
      $scope.getBadgeByCode = function (code, returnValue) {
        var found = $filter('filter')(badges, { niceName: code }, true);
        if (found.length) {
          return found[0][returnValue];
        }
      };

      serviceApiBlog.getPosts().then(function (result) {
        var arrayPosts = result.result;

        /* Para NO España */
        $scope.postsBanner = arrayPosts.filter(function (element) {
          return element.isVisibleHome;
        });

        $scope.featuredPost = $scope.postsBanner[0]; /* Para España */
      });

      $scope.getPostUrl = function(post) {
        var route = saRouter.getRoute('blogPost');
        return saRouter.replaceUrlParams(route.url, { postNiceName: _.get(post, "cms.url.slug") });
      }

      bannersFactory
        .getBanners(webStorage.get('userNiceName') ? true : false)
        .then(function () {
          $scope.banner_home = bannersFactory.getBanner('home');
        });

      $scope.$on('$viewContentLoaded', function () {
        $document.ready(function () {
          if ($location.search()['suggestions']) {
            $timeout(function () {
              var elem = $document[0].getElementById('suggestions');

              if (elem) {
                elem.scrollIntoView(true);
                window.scrollBy(0, -80);
              }
            }, 450);
          }
        });
      });

      // SPAIN
      if (appConfig.region == 'ES-MYCOOKTOUCH') {
        var projection = {
          img: 1,
          title: 1,
          niceName: 1,
          visibleHome: 1,
          _id: 0,
        };

        var queryParamsLandings = {
          onlyHome: true,
          limit: 10,
        };

        serviceLandings
          .getLandingsByType('landing', queryParamsLandings, projection)
          .then(function (landings) {
            $log.debug('HomeController > getLandingsByType', landings);
            $scope.landings = landings.results;
          });

        var queryParamsEbooks = {
          onlyHome: true,
          limit: 5,
        };

        serviceLandings
          .getLandingsByType('ebook', queryParamsEbooks, projection)
          .then(function (result) {
            $log.debug('HomeController > getLandingsByType', result);
            var ebooks = result.results;
            var featured_ebook = ebooks.shift();

            // ordenar destacado en tercera posicion
            if (featured_ebook) ebooks.splice(2, 0, featured_ebook);

            $scope.ebooks = ebooks.map(function (obj) {
              obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          });
      }
    },
  ]);
})();
